@if (languageChange$ | async; as _lang) {
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-30">
  <!--begin::Reset Password -->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="text-center mb-10 mb-lg-20">
      <!--begin::Title-->
      <h1 class="text-dark fw-bolder mb-3" transloco="label_setup_new_password"></h1>
      <!--end::Title-->
      <!--begin::Link-->
      <span class="text-gray-500 fw-semibold fs-6" transloco="sentence_have_you_already_reset_the_password">
      </span>
      <a [routerLink]="['/',_lang,'auth','login']" title="{{'label_back_to_sign_in' | transloco}}"
        class="link-primary fw-bold ms-2" transloco="label_sign_in">
      </a>
      <!--end::Link-->
    </div>
    <!--begin::Heading-->
    <!--begin::Form-->
    <form class="form" [formGroup]="changePasswordForm" autocomplete="off">
      <!-- begin:: New password -->
      <div class="form-group my-2">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_new_password"></mat-label>
          <input matInput id="password" [type]="hidePassword ? 'password' : 'text'" autocomplete="off"
            formControlName="password" />
          <mat-icon matSuffix class="me-2" (click)="hidePassword = !hidePassword">
            <i class="bi fs-2" [ngClass]="{'bi-eye-slash':hidePassword,' bi-eye':!hidePassword}"></i>
          </mat-icon>
          @if (changePasswordForm.controls.password.errors?.required) {
          <mat-error transloco="sentence_please_enter_password">
          </mat-error>
          }
          @if (changePasswordForm.controls.password.errors?.minlength) {
          <mat-error transloco="sentence_password_must_contain_msg">
          </mat-error>
          }
        </mat-form-field>
      </div>
      <!-- end:: New password -->
      <!-- begin:: Confirm password -->
      <div class="form-group my-2">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_confirm_password"></mat-label>
          <input matInput type="password" id="confirmPassword" autocomplete="off" formControlName="confirmPassword" />
          @if (changePasswordForm.controls.confirmPassword.errors?.required) {
          <mat-error transloco="sentence_please_enter_confirm_password">
          </mat-error>
          }
          @if (changePasswordForm.controls.confirmPassword.errors?.confirmedValidator) {
          <mat-error transloco="sentence_entered_password_does_not_match">
          </mat-error>
          }
        </mat-form-field>
      </div>
      <!-- end:: Confirm password -->
      <!--begin::Action-->
      <div class="d-grid mb-10">
        <button type="button" (click)="onSubmit()" [disabled]="loadingAfterSubmit" class="btn btn-primary">
          <!--begin::Indicator label-->
          <span class="indicator-label" transloco="label_submit"></span>
          <!--end::Indicator label-->
          <!--begin::Indicator progress-->
          @if (loadingAfterSubmit) {
          <span class="spinner-border spinner-border-sm ms-2"></span>
          }
          <!--end::Indicator progress-->
        </button>
      </div>
      <!--end::Action-->
      <!-- begin::Separator  -->
      <div class="text-center text-muted text-uppercase fw-bolder my-5 text-uppercase" transloco="label_or">
      </div>
      <!-- end::Separator  -->
      <a title="{{'label_back_to_home_page' | transloco}}" rel="follow" routerLink="/{{_lang}}"
        class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5 cursor-pointer">
        <i class="fas fa-home"></i>
        <span transloco="label_back_to_home_page"></span>
      </a>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Reset Password -->
</div>
}