@if (languageChange$ | async; as _lang) {
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-30">
  <!--begin::Forgot Password -->
  <div class="w-100">
    <div class="text-center mb-10 mb-lg-20">
      <h1 class="text-dark mb-3" transloco="label_forgotten_password"></h1>
      <p class="text-gray-400 fs-4" transloco="sentence_enter_email_to_reset_password"></p>
    </div>
    <form class="form" [formGroup]="forgotPasswordForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field class="mat-form-field-fluid">
          <mat-label transloco="label_email"></mat-label>
          <input matInput type="email" placeholder="{{'label_email' | transloco}}" formControlName="email"
            autocomplete="email" />
          @if (isControlHasError('email','required')) {
          <mat-error>
            <strong transloco="label_required_field"></strong>
          </mat-error>
          }
          @if (isControlHasError('email','email')) {
          <mat-error>
            <strong transloco="sentence_field_is_not_valid"></strong>
          </mat-error>
          }
          @if (isControlHasError('email','minLength')) {
          <mat-error>
            <strong transloco="dynamic_minimum_characters_hint" [translocoParams]="{ value: '3' }"></strong>
          </mat-error>
          }
          @if (isControlHasError('email','maxLength')) {
          <mat-error>
            <strong transloco="dynamic_maximum_characters_hint" [translocoParams]="{ value: '320' }"></strong>
          </mat-error>
          }
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between">
        <a [routerLink]="['/',_lang,'auth','login']" class="btn btn-light-primary px-9 py-4 my-3 mx-4"
          transloco="label_back">
        </a>
        <button [disabled]="loading" (click)="submit()" class="btn btn-primary px-9 py-4 my-3 mx-4">
          <span transloco="label_submit"></span>
          @if (loading) {
          <span class="spinner-border spinner-border-sm ms-2"></span>
          }
        </button>
      </div>
      <!--end::Action-->
      <!--begin::Separator-->
      <div class="separator separator-content my-14">
        <span class="w-125px text-gray-500 fw-semibold fs-7 text-uppercase" transloco="label_or"></span>
      </div>
      <!--end::Separator-->
      <a title="{{'label_back_to_home_page' | transloco}}" rel="follow" routerLink="/{{_lang}}"
        class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5 cursor-pointer">
        <i class="fas fa-home"></i>
        <span transloco="label_back_to_home_page"></span>
      </a>
    </form>
  </div>
</div>
}